// クレジット カード番号の自動フォーマット
$(document).on('keypress keyup','#card_number', function (e) {
    let self = $(this);
    if (e.keyCode !== 8) {
        var number = this.value;
        number = number.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
        });
        number = number.replace(/[^0-9a-zA-Z]/g, '');
        //number = this.value.replace(/[^\d ]/g, '');
        self.val(number);

        number = self.val().replace(/\W/gi, '');
        number = number.replace(/(.{4})/g, '$1 ');
        if (number.length <= 19) {
            self.val(number);
            return true;
        } else {
            number = number.substring(0, 19);
            self.val(number);
            e.preventDefault();
            return false;
        }
    }
})

// クレジット カードの有効期限の自動フォーマット
var input = document.querySelectorAll('.js-date')[0];
if (input) {
    dateInputMask(input);
}
var dateInputMask = function dateInputMask(elm) {
    elm.addEventListener('keypress', function (e) {
        if (e.keyCode < 47 || e.keyCode > 57) {
            e.preventDefault();
        }
        var len = elm.value.length;
        // 特定の場所にいる場合は、ユーザーにスラッシュを入力させます
        // つまり、12/12/1212
        if (len !== 1 || len !== 3) {
            if (e.keyCode == 47) {
                e.preventDefault();
            }
        }
        // スラッシュを追加しない場合は、追加してください...
        if (len === 2) {
            elm.value += '/';
        }

    });
};
